@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  /* border: none; */
  outline: none;
  box-sizing: border-box;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*:focus {
  outline: none;
}

body {
  font-family: "Manrope", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
  box-sizing: border-box;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .container {
    padding: 0 16px; /* Adjust padding for smaller screens */
  }
}

/* Additional media queries for even smaller screens if needed */
@media (max-width: 480px) {
  .container {
    max-width: 100%; /* Allow the container to take the full width on smaller screens */
  }
}

.full-width {
  width: 100%;
}
